import React, { useRef, useEffect, useState, memo } from "react";
import Button from "../inputs/Button";
import Stepper from "./Stepper";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountUpTimer from "../outputs/CountUpTimer";
import {
  updateTranslationValue,
  updateGlobalValue,
  updateMultipleGlobal,
  updateAdviceValue,
  updateNextButtonStatus,
  // updateAdviceScenarioComplete,
  updateCompleteScenarioByType,
  updateKeyByActivity,
} from "../../redux/slices/scenarioSlice";
import { current } from "@reduxjs/toolkit";

/*******************************
 * CONSTANTS:
 *******************************/
const endingLayoutPages = [
  "passage-english",
  "advice-instruction-review",
  "chat-instruction-review",
  "chat-written-instruction-review",
  "opinion-instruction-review",
];
const endingSegments = [
  "advice-exchange-student",
  "chat-safari",
  "chat-written-assignment",
  "opinion-pet-options"
];

const startingSegments = [
  "advice-instructions",
  "chat-instructions",
  "chat-written-instructions",
  "opinion-instructions",
];
const Footer = ({ nextBtnRef, nextBtnClicked }) => {
  // console.log("Footer re-rendered");
  /***************************************************
   * REDUX STATE VARIABLES
   ***************************************************/
  const scenarioData = useSelector((state) => state.scenarios);
  // const adviceData = scenarioData.advice;
  // const chatData = scenarioData.chat;
  const passageData = scenarioData.passage;
  const shiftedAudioComplete = passageData.shiftedAudioComplete;
  console.log("Footer: ShiftedAudioComplete:", shiftedAudioComplete);

  const globalData = scenarioData.global;
  const pageNames = globalData["page-names"];
  const stepperSubviews = globalData.subviews;
  const currentActivityType = globalData["current-activity-type"];
  // need to include the languageSelect and Instruction page (+2)
  const passageAudioFileLength =
    passageData["audio-translations"]["english"]?.audios.length + 2;
  console.log("PassageAudioLength:", passageAudioFileLength);
  /*****************************************
   * Determine How many passages are played
   *****************************************/
  // const passageByLanguageEndingIndex = 6;
  const passageByLanguageEndingIndex = passageAudioFileLength;

  // data depending on currenActivityType
  const dataByActivityType = scenarioData[currentActivityType];
  const shiftedAudioIndex = dataByActivityType?.shiftedAudioIndex;
  console.log(" shiftedAudioIndex:", shiftedAudioIndex);
  console.log("DataByActivityType:", dataByActivityType);
  console.log("SCENARIO DATA:", scenarioData.global);
  console.log("currentActivityType:", currentActivityType);
  const dataByScenarioType = scenarioData[currentActivityType];
  console.log("DataByScenarioType:", dataByScenarioType);
  const views =
    currentActivityType !== "completion" &&
    scenarioData[currentActivityType].views;
  // const allScenariosUploaded = adviceData["all-scenarios-uploaded"];
  const allScenariosUploaded =
    currentActivityType !== "completion" &&
    dataByActivityType["all-scenarios-uploaded"];

  const currentScenario =
    currentActivityType === "passage"
      ? scenarioData.passage
      : currentActivityType !== "completion"
        ? dataByActivityType.shiftedItem
        : null;

  console.log(
    "CurrentActivityType:",
    currentActivityType,
    "CurrentScenario:",
    currentScenario
  );
  // const chatScenarios = chatData.scenarios_copy;
  // const adviceScenarios = adviceData.scenarios_copy;
  const currentScenarios =
    currentActivityType !== "completion" && dataByActivityType.scenarios_copy;

  const dispatch = useDispatch();
  const nextBtnDisabled = globalData.nextBtnDisabled;
  const currentLayout = globalData["current-layout"];
  const currentStepperSegment = globalData["current-stepper-segment"];
  const currentSegmentIsTheEndingSegment = endingSegments.includes(
    currentStepperSegment
  );
  const currentSegmentIsTheStartingSegment = startingSegments.includes(
    currentStepperSegment
  );
  const stepperSegmentNames = stepperSubviews[currentActivityType];
  /**********************************
   * FUNCTIONS:
   ***********************************/
  /*******************************
   * Keep track of Page transitions
   ********************************/
  const layoutNames = useSelector(
    (state) => state.scenarios.global["layout-names"]
  );

  /**
   * Gets the next name in a activityType, pageLayout,
   * and segmentation list of names in Redux
   * @param {*} name
   * @param {*} type
   * @returns
   */
  const getNextName = (currentName, type) => {
    let nextName = "";
    let names = "";
    if (type === "activity") {
      names = pageNames;
    } else if (type === "layout") {
      names = layoutNames;
    } else if (type === "segment") {
      names = stepperSegmentNames;
    }
    const currentIndex = names.indexOf(currentName);
    const nextIndex = currentIndex + 1;
    nextName = names[nextIndex];
    console.log(
      `Footer: ${type} currentName: ${currentName} next name: ${nextName}`
    );
    return nextName;
  };

  const getNextActivityTypeSegmentationName = (nextActivityType) => {
    const segmentationGroup = stepperSubviews[nextActivityType];
    const newSegmentName = segmentationGroup[0];
    return newSegmentName;
  };

  // console.log("CURRENTPAGE:", currentActivityType, "CurrentLayout:", currentLayout, "currentStepperSegment:", currentStepperSegment);

  let footerData = {};
  let title = "";
  let label = "";
  const isCurrentStepperSegmentInStepperSubviews = stepperSegmentNames.includes(
    currentStepperSegment
  );

  if (isCurrentStepperSegmentInStepperSubviews) {
    // footerData = views[currentLayout].footer;
    console.log("currentStepperSegment1: views", views, "currentStepperSegment", currentStepperSegment)
    footerData =
      currentActivityType !== "completion" &&
      views[currentStepperSegment].footer;

    // console.log("ViewFooterData:", footerData);

    title = footerData.title;
    label = footerData.label;

    // console.log("FooterData:", footerData);
  }

  /*****************************************
   * Passage Transition: -View and Mark
   * passage-translation section complete
   * @param {*} event
   *****************************************/
  const handlePageTransition = (event) => {
    event.preventDefault();
    event.stopPropagation();
    nextBtnClicked.current = true;
    // const currentScenarioAdvice =
    //   currentActivityType === "advice" &&
    //   currentLayout === "advice-instruction-review" &&
    //   currentScenarios.length >= 0;

    // const currentScenarioChat =
    //   currentActivityType === "chat" &&
    //   currentLayout === "chat-instruction-review" &&
    //   currentScenarios.length >= 0;
    // console.log("FOOTER: CurrentScenario0:", currentScenario, "Advice:", currentScenarioAdvice, "Chat:", currentScenarioChat);

    /****************************************
     * (P1) Update Current-Layout after we have gotten to
     * the New Page Level, by clicking  the next button
     *****************************************/
    if (
      currentActivityType === "passage" &&
      (currentLayout === "language-select" ||
        currentLayout === "instructions" ||
        currentLayout === "passage-foreign")
    ) {
      const nextLayoutName = getNextName(currentLayout, "layout");

      console.log(
        "Passage Transition: NextLayoutName:",
        nextLayoutName,
        "CurrentLayoutName:",
        currentLayout
      );
      dispatch(
        updateGlobalValue({ key: "current-layout", value: nextLayoutName })
      );
    } //if
    /****************************************************************
     * (P2-Part1) Set Passage Scenario Complete,
     * when submit button is clicked on
     * Passage and PassageEnglish layout, 
     * when you are on the Last Audio file,
     * so data can start uploading to Firebase:
     ****************************************************************/
    else if (
      currentActivityType === "passage" &&
      currentLayout === "passage-english" &&
      shiftedAudioIndex === passageByLanguageEndingIndex
    ) {
      console.log(
        "Setting passage to complete:",
        "currentActivityType:",
        currentActivityType,
        "currentLayout:",
        currentLayout
      );

      // Sets just the Passage Activity Type to complete
      dispatch(
        updateTranslationValue({
          key: "complete",
          value: true,
        })
      );

      /***************************************************************** */
      /********************************
       * (P2-Part2) Set shiftedAudioComplete to true after
       * Passage-English is submited, so the data
       * can be uploaded, if shiftedAudioIndex < passageByLanguageEndingIndex,
       * transition back to the passage-english
       * segment to get the next passage-foreign segment
       *********************************/
    } else if (
      currentActivityType === "passage" &&
      currentLayout === "passage-english" &&
      shiftedAudioIndex < passageByLanguageEndingIndex &&
      !shiftedAudioComplete
    ) {
      console.log(
        "Setting Stepper Back to passage-foreign:",
        "currentActivityType:",
        currentActivityType,
        "currentLayout:",
        currentLayout
      );

      dispatch(updateNextButtonStatus({ key: "nextBtnDisabled", value: true }));

      // set passage-translation-complete to false
      dispatch(
        updateTranslationValue({
          key: "shiftedAudioComplete",
          value: true,
        })
      );
      // Redirect to passage-foreign in passage-english now
    } else if (
      /********************************
       * (A1) When submit button clicked for
       * ActivityType (Advice or Chat) Layout
       * AdviceInstructions (instruction),
       * Handle View & Stepper transitions
       *********************************/
      (currentActivityType === "advice" &&
        currentLayout === "advice-instructions") ||
      /*******************************************************
       * currentLayout === "chat-instruction-review" broke code
       ********************************************************/
      (currentActivityType === "chat" &&
        currentLayout === "chat-instructions") ||
      (currentActivityType === "chat-written" &&
        currentLayout === "chat-written-instructions") ||
      (currentActivityType === "opinion" &&
        currentLayout === "opinion-instructions")
    ) {
      // console.log(
      //   "layoutNames:",
      //   layoutNames,
      //   "currentLayout:",
      //   currentLayout,
      //   "currentStepperSegment:",
      //   currentStepperSegment
      // );
      const nextLayoutName = getNextName(currentLayout, "layout");
      console.log(
        "Footer:NextLayoutName:",
        nextLayoutName,
        "CurrentLayout:",
        currentLayout
      );
      // update layout
      dispatch(
        updateGlobalValue({
          key: "current-layout",
          value: nextLayoutName
        })
      );

      const nextSegmentName = getNextName(currentStepperSegment, "segment");
      // update stepper segment
      dispatch(
        updateGlobalValue({
          key: "current-stepper-segment",
          value: nextSegmentName,
        })
      );
    }

    /*************************************************************
    (A2) : For ActivityType: Advice or Chat, an layout is an 
    InstructionReview (endPage), then set the activityType to complete, 
    so transitioning to next type can begin, Layout AdviceInstructReview,
    (1)When recording is done on the current scenario
    if it is not marked as complete, mark it complete &
    (2) Update the current stepper value, only if currentSegmentStepper
    not in endingSegment
(3) Release button focus and disable button to start the next scenario.
   
    NOTE: This will repeat for each scenario
    **************************************************************/

    // (currentScenarioAdvice || currentScenarioChat) &&
    else if (
      (currentActivityType === "advice" ||
        currentActivityType === "chat" ||
        currentActivityType === "chat-written" ||
        currentActivityType === "opinion") &&
      !currentScenario.complete
    ) {
      console.log(
        "FOOTER: CurrentScenario0:",
        currentActivityType,
        currentActivityType
      );

      // console.log("FOOTER: CurrentScenario1:",
      //   currentScenario, "currentLayout:",
      //   currentLayout,
      //   "ENDLAYOUTPAGES:",
      //   endingLayoutPages,
      //   "CurrentLayoutInEndLayout:",
      //   "CurrentStepper:", currentStepperSegment,
      //   "ENDINGSEGMENTS:", endingSegments,
      //   "CurrenSegmentInEndingSegments:",
      //   currentSegmentIsTheEndingSegment
      //   );
      // console.log("FOOTER: AdviceScenarios:", adviceScenarios);

      /**********************************
       * (1) Update the currentScenario (advice/shiftedItem) to complete
       ***********************************/
      // dispatch(
      //   updateAdviceScenarioComplete({
      //     value: true,
      //   })
      // );
      dispatch(
        updateCompleteScenarioByType({
          activityType: currentActivityType,
          complete: true,
        })
      );
      /************************************
       * (2)Update the Stepper Segment only if
       * the current segment is not the ending segment
       ***********************************/
      if (!currentSegmentIsTheEndingSegment) {
        const nextSegmentName = getNextName(currentStepperSegment, "segment");
        console.log(
          "FOOTER: NextSegmentName:",
          nextSegmentName,
          "currentStepperSegment:",
          currentStepperSegment
        );
        dispatch(
          updateGlobalValue({
            key: "current-stepper-segment",
            value: nextSegmentName,
          })
        );
      }

      /**********************************
       * (3) Unfocus the button and disable it
       * to prevent accidently submitting audio
       * before time has expired.
       ***********************************/
      nextBtnRef.current.blur();

      // if (endingLayoutPages.includes(currentLayout)) {
      dispatch(updateNextButtonStatus({ key: "nextBtnDisabled", value: true }));
    }
  };
  /****************************************************
   * USEEFFECTS
   ****************************************************/
  /****************************
   * (P3) Change for passage activityType
   ****************************/
  /*********************************************
   * 8/12/2024: Passage Data: Does not have a shiftedItem
   * Therefore the transition to Type 2 occurs after
   * passage.complete is set on the passage scenario.
   *********************************************/
  useEffect(() => {
    // (1 & 2) Update currentActivityType to the next current layout
    if (
      currentActivityType === "passage" &&
      currentLayout === "passage-english" &&
      currentScenario.complete
    ) {
      // console.log("Passage.complete:", passageData.complete)
      // Update current-layout and current-activity-type
      dispatch(
        updateMultipleGlobal({
          "current-activity-type": "advice",
          "current-layout": "advice-instructions",
          "current-stepper-segment": "advice-instructions",
        })
      );
    }
  }, [passageData.complete]);
  /*********************************************************/
  /****************************
   *After the last scenario (shiftedItem) in the
   * activity type is complete, Set the ENTIRE
   * activity type (advice, chat) to complete
   * (1) Set (advice, chat) type to complete
   ****************************/
  useEffect(() => {
    console.log(
      "Clicked button when AllsceiariosUploaded:",
      allScenariosUploaded
    );
    console.log(
      `&CompletingActivityType: ${currentActivityType}: `,
      currentActivityType,
      "currentScenario:",
      currentScenario,
      "correntLayout:",
      currentLayout
      // "currentScenarios.length:",
      // currentScenarios.length,
      // "currentScenario.complete:",
      // currentScenario.complete,
      // "allScenariosUploaded:",
      // allScenariosUploaded
    );
    if (
      ((currentActivityType === "advice" &&
        currentLayout === "advice-instruction-review") ||
        (currentActivityType === "chat" &&
          currentLayout === "chat-instruction-review") ||
        (currentActivityType === "chat-written" &&
          currentLayout === "chat-written-instruction-review") ||
        (currentActivityType === "opinion" &&
          currentLayout === "opinion-instruction-review")) &&
      currentScenarios.length === 0 &&
      currentScenario.complete &&
      allScenariosUploaded
    ) {
      //  console.log(`ActivityType: ${currentActivityType}: Value Compete: Scenario: `,  currentActivityType === "advice" ? adviceData.complete : chatData.complete);
      /******************************************
       * Make the ENTIRE advice activity type complete
       *****************************************/
      //  dispatch(updateAdviceValue({ key: "complete", value: true }));
      dispatch(
        updateKeyByActivity({
          activityType: currentActivityType,
          key: "complete",
          value: true,
        })
      );
    }
  }, [allScenariosUploaded, currentScenario]);

  /*********************************
   * After the entire activity type is complete,
   * begin transitioning to new layout and
   * activity type:
   * (1) Set the New Layout and
   * (2) the new ActivityType
   **********************************/
  /******************
   * 8/12/2024
   ******************/
  // useEffect(() => {
  //   // (1 & 2) Update currentActivityType to the next layout
  //   if (
  //     currentActivityType === "advice" &&
  //     currentLayout === "advice-instruction-review" &&
  //     adviceScenarios.length === 0 &&
  //     currentScenario.complete &&
  //     adviceData.complete
  //   ) {
  //     // console.log("adviceData.complete:", adviceData.complete)
  //     // Update current-layout and current-activity-type
  //     dispatch(
  //       updateMultipleGlobal({
  //         "current-activity-type": "chat",
  //         "current-layout": "chat-instructions",
  //         "current-stepper-segment": "chat-instructions",
  //       })
  //     );
  //   }
  // }, [adviceData.complete]);

  /********************************
   * Advice, Chat: Transition to next Type
   * once endpage is complete
   ********************************/
  useEffect(() => {
    const currentLayoutIsEndPage = endingLayoutPages.includes(currentLayout);
    console.log(
      "CurrentLayout:0",
      currentLayout,
      "currentLayoutIsEndPage",
      currentLayoutIsEndPage,
      "dataByActivityType.complete:",
      dataByActivityType?.complete,
      "currentSegmentIsTheEndingSegment:",
      currentSegmentIsTheEndingSegment,
      "currentStepperSegment:",
      currentStepperSegment,
      "EndingSegments:",
      endingSegments
    );

    if (
      currentActivityType !== "passage" &&
      dataByActivityType?.complete &&
      currentSegmentIsTheEndingSegment
    ) {
      const nextActivityType = getNextName(currentActivityType, "activity");
      const nextLayoutName = getNextName(currentLayout, "layout");
      // const nextSegmentName = getNextName(currentStepperSegment, "segment");
      const nextSegmentName =
        getNextActivityTypeSegmentationName(nextActivityType);
      console.log(
        "Advice/Chat Next Type Transtion: CurrentLayout:1",
        currentLayout,
        "currentLayoutIsEndPage:",
        currentLayoutIsEndPage,
        "currentSegment:",
        currentStepperSegment,
        "nextActivityType:",
        nextActivityType,
        "nextLayoutName:",
        nextLayoutName,
        "nextSegmentName:",
        nextSegmentName
      );
      // Update current-layout and current-activity-type
      dispatch(
        updateMultipleGlobal({
          "current-activity-type": nextActivityType,
          "current-layout": nextLayoutName,
          "current-stepper-segment": nextSegmentName,
        })
      );
    }
  }, [dataByActivityType?.complete]);

  /*********************************************
   * Transition to the next Segment with chat: chat
   *(1) Transition only if the segment is not the
   * ending segment
   *********************************************/
  // useEffect(() => {
  //   /************************************
  //    * (1)Update the Stepper Segment only if
  //    * the current segment is not the ending segment
  //    ***********************************/
  //   console.log("transitioning to the next segment:")
  //   if ( (currentActivityType !== "passage") &&
  //     (currentActivityType === "advice" || currentActivityType === "chat") &&
  //     !currentSegmentIsTheEndingSegment
  //     && !currentSegmentIsTheStartingSegment
  //     && currentScenario.complete
  //   ) {
  //     const nextSegmentName = getNextName(currentStepperSegment, "segment");
  //     console.log(
  //       "FOOTER: NextSegmentName:",
  //       nextSegmentName,
  //       "currentStepperSegment:",
  //       currentStepperSegment
  //     );
  //     dispatch(
  //       updateGlobalValue({
  //         key: "current-stepper-segment",
  //         value: nextSegmentName,
  //       })
  //     );
  //   }
  // }, [currentScenario.complete]);

  /*******************************************
   *Each time we go to a new Layout, release button focus
   *Note this does not work for single page apps, the focus
   * must be reset when data is refreshed
   *******************************************/
  useEffect(() => {
    // console.log(
    //   "FOOTER: CurrentActivityType:",
    //   currentActivityType,
    //   "CurrentLayout:",
    //   currentLayout
    // );
    // Unfocus the button after the click event
    nextBtnRef.current?.blur();

    /****************************************************
     * 9/25/2024: Disable the submit button when directed  
     * to "passage-english",per Jooyoung request
     *****************************************************/
    if (endingLayoutPages.includes(currentLayout) && currentLayout !== "passage-english") {
      // if (endingLayoutPages.includes(currentLayout)) {
      dispatch(updateNextButtonStatus({ key: "nextBtnDisabled", value: true }));
      /*****************************************************/
    }
  }, [currentLayout]);

  useEffect(() => {
    // console.log("NEXTBTNDISABLED:", nextBtnDisabled);
  }, [currentLayout, nextBtnDisabled]);

  return (
    <>
      {currentActivityType !== "completion" ? (
        <div className="footer-container">
          {currentLayout === "language-select" ? (
            <>
              <div className="footer-item title">
                {/* <div className="content label" style={{width: "165px"}} /> */}

                <div className="footer-item bar">
                  <div className="content page" style={{ width: "60px" }} />
                  <div className="stepper" style={{ width: "825px" }} />
                </div>
              </div>
              {/*<div className="footer-item clock" style={{width: "173px"}}/> */}
            </>
          ) : (
            <>
              <div className="footer-item title">
                <div className="content label">{title}</div>
              </div>

              <div className="footer-item bar">
                <div className="content page">{label}</div>

                <div className="stepper">
                  <Stepper />
                </div>
              </div>

              {currentActivityType !== "advice" ? (
                <div className="footer-item clock">
                  <FontAwesomeIcon
                    icon="fa-regular fa-clock"
                    style={{ color: "#A7A7A7" }}
                  />

                  <CountUpTimer />
                </div>
              ) : null}
            </>
          )}

          <div className="footer-item button">
            {/* {console.log("NEXTBTNDISABLED:", nextBtnDisabled)} */}
            <Button
              pointer={nextBtnRef}
              buttonClass="stepper-btn"
              label={
                endingLayoutPages.includes(currentLayout) ? "I'm Done" : "Next"
              }
              variant="primary"
              size="lg"
              onClick={handlePageTransition}
              {...(nextBtnDisabled && { disabled: nextBtnDisabled })}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default memo(Footer);
