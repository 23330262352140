export const playAudioBlob = async (audioBlob) => {
    try {
      const audio = new Audio();
      const blobUrl = URL.createObjectURL(audioBlob);
      audio.src = blobUrl;
      audio.load();
      audio.play();
      audio.onended = () => {
        URL.revokeObjectURL(blobUrl);
      };
      return audio;
    } catch (error) {
      console.error("Error playing audio:", error);
      throw error;
    }
  };
  