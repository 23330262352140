
import {franc, francAll} from 'franc'
import {transcribeAudio} from "./OpenAi"
import { playAudioBlob } from './playAudioBlob';

// export const analyzeRealTimeAudioWithWebSpeechAPI = (stream, audioContext) => {
//   return new Promise((resolve) => {
//     // Check for the Web Speech API
//     const SpeechRecognition =
//       window.SpeechRecognition || window.webkitSpeechRecognition;

//     if (!SpeechRecognition) {
//       console.error("Web Speech API not supported");
//       resolve(false); // Web Speech API is not supported, resolve with false
//       return;
//     }

//     const recognizer = new SpeechRecognition();
//     recognizer.lang = "en-US";
//     recognizer.continuous = false;
//     recognizer.interimResults = false;

//     // Removed the guts
//     recognizer.onresult = (event) => {
//       console.log("Results:", event.results);
//       // Results will show your transcript of what you said
//       const transcript = event.results[0][0].transcript;
//       const confidence = event.results[0][0].confidence;
//       console.log(
//         "AnalyzerEvent: Transcript:",
//         transcript,
//         "Confidence:",
//         confidence
//       );
//       if (transcript.length > 0) {
//             resolve(true); // Resolve with true if speech is detected
//           } else {
//             resolve(false); // Resolve with false if no speech is detected
//           }
//     };

//     recognizer.onerror = (event) => {
//         // console.error("Speech recognition error:", event.error);
//         resolve(false); // Resolve with false if there's an error
//       };
      
//       recognizer.onend = () => {
//         // console.log("Speech recognition ended");
//         // Resolve to false if no result or error event fired
//         resolve(false);
//       };
    

//     // Attach the MediaStream to the recognizer
//     const mediaStreamSource = audioContext.createMediaStreamSource(stream);
//     recognizer.start();
//   });
// };



export const checkTranscribedAudio = async (stream) => {
  return new Promise((resolve, reject) => {
    // Create an AudioContext to process the audio data
    const audioContext = new AudioContext();
    
    // Create a source node from the stream
    const source = audioContext.createMediaStreamSource(stream);
    
    // Create a processor node to handle the audio data
    const processor = audioContext.createScriptProcessor(4096, 1, 1); // Buffer size of 4096 samples
    
    // Connect the source to the processor and the processor back to the audio context destination
    source.connect(processor);
    processor.connect(audioContext.destination);

    // Handle the real-time audio processing
    processor.onaudioprocess = async (event) => {
      const inputBuffer = event.inputBuffer;
      const channelData = inputBuffer.getChannelData(0); // Get audio data from the first channel

      // Convert the audio data to a Blob for transcription
      const audioBlob = new Blob([new Float32Array(channelData)], { type: "audio/wav" });
      console.log("CheckTranscribedAudio: playingAudio")
      playAudioBlob(audioBlob);

      try {
        // Send the blob to Whisper for transcription
        const transcribedText = await transcribeAudio(audioBlob);
        console.log("CheckTranscribedAudio: transcribedText:", transcribedText)
        
        // Check if any English words are found in the transcription
        if (transcribedText && await containsEnglishWord(transcribedText)) {
          console.log("Transcribed Text:", transcribedText);
          processor.disconnect(); // Stop further processing if words are detected
          resolve(true); // Resolve with true if English words are detected
        }
      } catch (error) {
        console.error("Error during audio transcription:", error);
        processor.disconnect();
        reject(false); // Handle transcription failure
      }
    };

    // Timeout to stop processing after a defined time (e.g., 10 seconds)
    setTimeout(() => {
      processor.disconnect();
      resolve(false); // Resolve as false if no valid English words are detected after processing
    }, 10000); // Time limit for analyzing the stream
  });
};

/**
 * Check if the transcribed text contains English words using a language detection library.
 *
 * @param {string} transcribedText - The text that was transcribed from the audio stream.
 * @returns {Promise<boolean>} - A promise that resolves to true if the language is detected as English.
 */
export const containsEnglishWord = async (transcribedText) => {
  return new Promise((resolve) => {
    // Detect the language from the transcribed text
    const languageCode = franc(transcribedText);

    // 'eng' is the language code for English in ISO 639-3
    if (languageCode === 'eng') {
      console.log("English language detected");
      resolve(true); // English words are present
    } else {
      console.log("No English language detected");
      resolve(false); // No English words are present
    }
  });
};



// export const analyzeRealTimeAudioWithWebSpeechAPI = (stream) => {
//   return new Promise((resolve) => {
//     const SpeechRecognition =
//       window.SpeechRecognition || window.webkitSpeechRecognition;

//     if (!SpeechRecognition) {
//       console.error("Web Speech API not supported");
//       resolve(false);
//       return;
//     }

//     const recognizer = new SpeechRecognition();
//     recognizer.lang = "en-US";
//     recognizer.continuous = false;
//     recognizer.interimResults = false;

//     recognizer.onresult = async (event) => {
//       const transcript = event.results[0][0].transcript;
//       const confidence = event.results[0][0].confidence;

//       console.log("Transcript:", transcript, "Confidence:", confidence);

//       if (transcript.length > 0 && confidence > 0.6) {
//         resolve(true); // Speech recognized with good confidence
//       } else {
//         console.log("Low confidence, analyzing stream with transcribeAudio...");
//         // Call checkTranscribedAudio to analyze the stream further
//         const hasEnglishWord = await checkTranscribedAudio(stream);
//         resolve(hasEnglishWord);
//       }
//     };

//     recognizer.onerror = (event) => {
//       console.error("Speech recognition error:", event.error);
//       resolve(false);
//     };

//     recognizer.onend = () => {
//       // Only resolve with false if the promise hasn't been resolved yet
//       if (!resolved) {
//         console.log("Speech recognition ended");
//         resolve(false);
//       }
//     };

//     let resolved = false;

//     recognizer.start();
//   });
// };

export const analyzeRealTimeAudioWithWebSpeechAPI = (stream) => {
  return new Promise((resolve) => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) {
      console.error("Web Speech API not supported");
      resolve(false);
      return;
    }

    const recognizer = new SpeechRecognition();
    recognizer.lang = "en-US";
    recognizer.continuous = false;
    recognizer.interimResults = false;

    // Flag to track if the promise has been resolved
    let resolved = false;

    recognizer.onresult = async (event) => {
      const transcript = event.results[0][0].transcript;
      const confidence = event.results[0][0].confidence;

      

      if (transcript.length > 0 && confidence > 0.6) {
        console.log("Transcript:", transcript, "Confidence HIGH:", confidence);
        resolved = true; // Mark as resolved
        resolve(true); // Speech recognized with good confidence
      } else {
        console.log("Confidence LOW: Analyzing stream with transcribeAudio...");
        // Call checkTranscribedAudio to analyze the stream further
        const hasEnglishWord = await checkTranscribedAudio(stream);
        resolved = true; // Mark as resolved
        resolve(hasEnglishWord);
      }
    };

    recognizer.onerror = (event) => {
      const transcript = event;
      const confidence = event;

      console.log("Transcript:", transcript, "Confidence:", confidence);
      console.error("Speech recognition error:", event.error);
      resolved = true; // Mark as resolved
      resolve(false);
    };

    recognizer.onend = () => {
      // Only resolve with false if the promise hasn't been resolved yet
      if (!resolved) {
        console.log("Speech recognition ended");
        resolve(false);
      }
    };

    recognizer.start();
  });
};

